import { Box, Grid } from "@mui/material";
import { Button, Input } from "../../../common/FormComponents";

import { getStyles } from "src/styles/theme";
import { useForm } from "react-hook-form";
import errorMessage from "src/constants/errorMessage";
import { useMutation } from "react-query";
import { AxiosResponse } from "axios";
import {
  updateSeedlingRowDetailsData,
  addSeedlingRowDetailsData,
} from "src/utils/api/dashboard"; // Assuming `addSeedlingData` exists
import { useEffect } from "react";
import defaultStyles, { StylesClasses } from "./styles";
import { useSearchParams } from "react-router-dom";

export function SeedlingForm({ mode, rowData, selfState, refetch }: any) {
  const styles = getStyles<StylesClasses>(defaultStyles);
  const [searchParams] = useSearchParams();

  const landOwnershipEpid = searchParams.get("id");

  const { mutate, isLoading } = useMutation(
    mode === "edit" ? "updateSeedlingData" : "addSeedlingData",
    ({ formData }: { formData: any }) =>
      mode === "edit"
        ? updateSeedlingRowDetailsData(rowData.id, formData)
        : addSeedlingRowDetailsData(formData),
    {
      onSuccess: (res: AxiosResponse) => {
        console.log(`${mode === "edit" ? "Updated" : "Added"} successfully`);
        selfState(false);
        refetch();
      },
      onError: (error: any) => {
        console.error(
          `Error occurred while ${mode === "edit" ? "updating" : "adding"} data`
        );
      },
    }
  );

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<any>({
    mode: "onChange",
    defaultValues: {
      commonName: mode === "edit" ? rowData?.commonName : "",
      botanicalName: mode === "edit" ? rowData?.botanicalName : "",
      numberOfSeedlings: mode === "edit" ? rowData?.numberOfSeedlings : "",
    },
  });

  useEffect(() => {
    if (mode === "edit") {
      reset({
        commonName: rowData?.commonName,
        botanicalName: rowData?.botanicalName,
        numberOfSeedlings: rowData?.numberOfSeedlings,
      });
    }
  }, [rowData, mode]);

  const onSubmit = (data: any) => {
    const formData = {
      landOwnershipEpid,
      commonName: data.commonName,
      botanicalName: data.botanicalName,
      numberOfSeedlings: parseInt(data.numberOfSeedlings, 10),
    };
    mutate({ formData });
  };

  return (
    <Box {...styles("wrapper")}>
      <Box
        {...styles("formWrapper")}
        component="form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Input
              customStyles={{
                label: defaultStyles.label,
                wrapper: defaultStyles.selectWrapper,
                input: {
                  borderColor: "#36B1A0;",
                  boxShadow: "unset",
                },
              }}
              name="commonName"
              rules={{
                required: errorMessage.required,
                maxLength: {
                  value: 500,
                  message: "More than 500 characters are not allowed",
                },
              }}
              control={control}
              errors={errors}
              placeholder="Enter Common Name"
              label="Common Name"
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <Input
              customStyles={{
                label: defaultStyles.label,
                wrapper: defaultStyles.selectWrapper,
                input: {
                  borderColor: "#36B1A0;",
                  boxShadow: "unset",
                },
              }}
              rules={{ required: errorMessage.required }}
              name="botanicalName"
              control={control}
              placeholder="Enter Botanical Name"
              label="Botanical Name"
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <Input
              customStyles={{
                label: defaultStyles.label,
                wrapper: defaultStyles.selectWrapper,
                input: {
                  borderColor: "#36B1A0;",
                  boxShadow: "unset",
                },
              }}
              name="numberOfSeedlings"
              rules={{ required: errorMessage.required }}
              control={control}
              errors={errors}
              placeholder="Enter Number of Seedlings"
              label="Number of Seedlings"
              type="number"
            />
          </Grid>
        </Grid>
        <Box>
          <Button
            text={mode === "edit" ? "Update" : "Add"}
            type="submit"
            loading={isLoading}
          />
        </Box>
      </Box>
    </Box>
  );
}

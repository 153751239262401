import styled from "@emotion/styled";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { getStyles } from "src/styles/theme";
import defaultStyles from "./styles";
import { useMutation } from "react-query";
import { AxiosError, AxiosResponse } from "axios";
import { deleteCostEstimateDetails } from "src/utils/api/dashboard";
import { useAppSelector } from "src/redux/store";

export type DashBoardTable = {
  topHeader: string[];
  data: any;
  onEdit?: (row: any) => void;
  onDelete?: (row: any) => void;
  status?: string;
};

export function SeedlingTable({
  data,
  topHeader,
  onEdit,
  onDelete,
  status,
}: DashBoardTable) {
  const styles = getStyles(defaultStyles);
  const user = useAppSelector((state) => state.root.user);
  return (
    <TableContainer component={Paper} sx={{ height: "fit-content" }}>
      <Table sx={{ minWidth: 600 }} aria-label="simple table">
        <TableHead {...styles("header")}>
          <TableRow>
            {topHeader.map((item, index) => (
              <TableCell align="center" {...styles("cell")} key={index}>
                {item}
              </TableCell>
            ))}
            {(status === "DRAFT" || status === "UNDER_QUERY") &&
              user?.data?.userType !== "INDIVIDUAL" && (
                <TableCell align="center" {...styles("cell")}>
                  Actions
                </TableCell>
              )}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((seedData, index) => {
            if (!seedData) return null;
            return (
              <TableRow key={index}>
                <TableCell
                  align="center"
                  {...styles("cell", { width: "100px" })}
                >
                  {seedData.commonName}
                </TableCell>
                <TableCell
                  align="center"
                  {...styles("cell", { width: "100px" })}
                >
                  {seedData.botanicalName}
                </TableCell>
                <TableCell
                  align="center"
                  {...styles("cell", { width: "100px" })}
                >
                  {seedData.numberOfSeedlings}
                </TableCell>

                {(status === "DRAFT" || status === "UNDER_QUERY") &&
                  user?.data?.userType !== "INDIVIDUAL" && (
                    <TableCell
                      align="center"
                      {...styles("cell", { width: "100px" })}
                    >
                      <>
                        <IconButton
                          aria-label="edit"
                          onClick={() => onEdit(seedData)}
                        >
                          <EditIcon />
                        </IconButton>
                        <IconButton
                          aria-label="delete"
                          onClick={() => onDelete(seedData.id)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </>
                    </TableCell>
                  )}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
